<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="false"
      variant="light"
      opacity="0.5"
      :blur="'true'"
      rounded="sm"
    >
      <b-card
        :title="`ข้อมูลกลุ่มลูกค้า`"
      >
        <b-row>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ขื่อกลุ่ม
            </h5>
            <b-form-input
              id="mc-user-name"
              v-model="groupForm.name"
              placeholder="ขื่อกลุ่ม"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ป้ายแท็ก (แสดงหลังชื่อลูกค้า)
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="groupForm.tag"
              placeholder="ป้ายแท็ก"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              สี (Code สี #XXXXXX)
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="groupForm.color"
              placeholder="สี"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              Icon
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="groupForm.icon"
              placeholder="Icon"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            class="mt-10"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveMemberGroup(groupInfo.id, groupForm, 'loadingProfile')"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-card
      title="เงื่อนไขการปรับกลุ่มอัตโนมัติ"
    >
      <b-row>
        <b-col md="2">
          <h5 class="text-capitalize mb-75">
            ปรับกลุ่มอัตโนมัติ
          </h5>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="groupForm.auto_apply"
            name="flavour-1"
          >
            <b-form-checkbox
              :value="true"
              switch
            >
              {{ (groupForm.auto_apply || []).length > 0 == true ? 'ใช่':'ไม่ใช่' }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <h5 class="text-capitalize mb-75">
            เงื่อนไขอายุสมาชิก
          </h5>
          <b-form-group>
            <b-input-group
              append="วัน"
            >
              <b-form-input
                v-model="groupForm.condition_registered_age"
                placeholder="เป็นสมาชิกมาแล้ว (วัน)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <h5 class="text-capitalize mb-75">
            เงื่อนไขยอดฝาก
          </h5>
          <b-form-group>
            <b-input-group
              append="ครั้ง"
            >
              <b-form-input
                v-model="groupForm.condition_deposit_count"
                placeholder="ฝากเงินแล้ว (ครั้ง)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h5 class="text-capitalize mb-75">
            หรือฝากรวมแล้ว
          </h5>
          <b-form-group>
            <b-input-group
              append="บาท"
            >
              <b-form-input
                v-model="groupForm.condition_total_deposits"
                placeholder="ฝากเงินแล้ว (ยาท)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <h5 class="text-capitalize mb-75">
            เงื่อนไขยอดแทงต่อไม้
          </h5>
          <b-form-group>
            <b-input-group
              append="บาท"
            >
              <b-form-input
                v-model="groupForm.condition_min_bet"
                placeholder="จำนวนเงินขั้นต่ำต่อไม้ (บาท)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h5 class="text-capitalize mb-75">
            หรือยอดเทิร์นสะสมรวม
          </h5>
          <b-form-group>
            <b-input-group
              append="บาท"
            >
              <b-form-input
                v-model="groupForm.condition_total_bet"
                placeholder="ยอดเทิร์นสะสมรวม (ยาท)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <h5 class="text-capitalize mb-75">
            หมายเหตุ: หากลูกค้าเข้าเงื่อนไขอย่างใดอย่างหนึ่งข้างต้น ระบบจะปรับกลุ่มลูกค้าให้อัตโนมัติ
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class=""
        >
          <b-button
            variant="primary"
            @click="saveMemberGroup(groupInfo.id, groupForm, 'loadingProfile')"
          >
            บันทึก
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="บัญชีฝาก"
    >
      <b-row>
        <b-col md="12">
          <h5 class="text-capitalize mb-75">
            บัญชีที่แสดง 1 (SCB)
          </h5>
          <v-select
            v-model="groupForm.bankaccount_a_id"
            label="title"
            :options="bankAccounts.filter(b => b.bank_code.toLowerCase() === '014')"
            :reduce="bankItem => bankItem.id"
          >
            <template #option="data">
              <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col md="12 mt-1">
          <h5 class="text-capitalize mb-75">
            บัญชีที่แสดง 2 (SCB)
          </h5>
          <v-select
            v-model="groupForm.bankaccount_b_id"
            label="title"
            :options="bankAccounts.filter(b => b.bank_code.toLowerCase() === '014')"
            :reduce="bankItem => bankItem.id"
          >
            <template #option="data">
              <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col md="12 mt-1">
          <h5 class="text-capitalize mb-75">
            บัญชีที่แสดง 3 (SCB)
          </h5>
          <v-select
            v-model="groupForm.bankaccount_c_id"
            label="title"
            :options="bankAccounts.filter(b => b.bank_code.toLowerCase() === '014')"
            :reduce="bankItem => bankItem.id"
          >
            <template #option="data">
              <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col md="12 mt-1">
          <h5 class="text-capitalize mb-75">
            บัญชีที่แสดง 4 (KBANK)
          </h5>
          <v-select
            v-model="groupForm.bankaccount_kb_id"
            label="title"
            :options="bankAccounts.filter(b => b.bank_code.toLowerCase() === '004')"
            :reduce="bankItem => bankItem.id"
          >
            <template #option="data">
              <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col md="12 mt-1">
          <h5 class="text-capitalize mb-75">
            บัญชีที่แสดง (ทรูวอลเล็ท)
          </h5>
          <v-select
            v-model="groupForm.bankaccount_tmw_id"
            label="title"
            :options="bankAccounts.filter(b => b.bank_code.toLowerCase() === 'tmw')"
            :reduce="bankItem => bankItem.id"
          >
            <template #option="data">
              <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="mt-10"
        >
          <b-button
            variant="primary"
            @click="saveMemberGroup(groupInfo.id, groupForm, 'loadingProfile')"
          >
            บันทึก
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BCardText, BOverlay, BFormCheckbox, BFormCheckboxGroup, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    vSelect,
    BInputGroup,
    // BBadge,
    BFormGroup,
    // BFormSelect,
    BPagination,
    // BCardText,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormCheckbox,
    BFormCheckboxGroup,
    // Cleave,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mode: 'update',
      groupInfo: {},
      groupForm: {},

      bankAccounts: [],
      bankMaps: {
        '004': 'kbank', '014': 'scb', '006': 'krungthai', TMW: 'tmw',
      },
      // loadingProfile: false,
      // userInput: null,
      // isBusy: false,
      // gauth: false,
      // gauthQR: null,
      // localVariables: {},
      // pageOptions: [50, 100, 200, 500, 1000],
      // totalWalletRows: 1,
      // sortBy: '',
      // sortDesc: false,
      // sortDirection: 'asc',
      // filter: null,
      // filterOn: [],
      // tableQuery: {
      //   page: 1,
      //   page_size: 50,
      // },
      // infoModal: {
      //   id: 'info-modal',
      //   title: '',
      //   content: '',
      // },
      // fields: [
      //   { key: 'created_at', label: 'วันที่' },
      //   { key: 'username', label: 'ยูส/เบอร์' },
      //   { key: 'note', label: 'รายละเอียด' },
      //   { key: 'amount', label: 'จำนวน' },
      //   { key: 'balance', label: 'คงเหลือ' },
      //   { key: 'ref2', label: 'อ้างอิง 2' },
      //   { key: 'ref3', label: 'อ้างอิง 3' },
      //   { key: 'platform', label: 'ระบบ' },
      // ],
      // activity_fields: [
      //   { key: 'date', label: 'วันที่' },
      //   { key: 'hour', label: 'เวลา (นาฬิกา)' },
      //   { key: 'stat_type', label: 'ประเภท' },
      //   { key: 'game', label: 'ค่ายเกมส์' },
      //   { key: 'ip', label: 'IP' },
      // ],
      // /* eslint-disable global-require */
      // groupInfo: null,
      // groupForm: {
      //   is_active: [true],
      // },
      // allpermissions: [],
      // userActivities: [],
      /* eslint-disable global-require */
    }
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter(f => f.sortable)
    //     .map(f => ({ text: f.label, value: f.key }))
    // },
  },
  mounted() {
    this.fetchBankAccounts()
    if (!this.$route.params.groupId) {
      this.mode = 'add'
      this.groupInfo = {}
      this.groupForm = {}
    } else {
      this.fetchMemberGroupsDetail(this.$route.params.groupId)
      this.mode = 'update'
    }
  },
  methods: {
    async fetchMemberGroupsDetail(id) {
      const { data: { member_group: memberGroup } } = await this.$http.get(`users/groups/${id}`)
      this.groupForm = { ...memberGroup, auto_apply: memberGroup.auto_apply ? [true] : [] }
      this.groupInfo = { ...memberGroup, auto_apply: memberGroup.auto_apply ? [true] : [] }
      return this.items
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts?mode=v2')
      this.bankAccounts = _bankaccounts.bankaccounts.filter(i => i.acc_type === 'IN').map(i => ({
        ...i, title: `${this.bankMaps[i.bank_code]} ${i.acc_no} ${i.acc_name}`, acc_type: i.acc_type, queue_name: i.bank_alias,
      }))
    },
    async saveMemberGroup(id, changes, loadingRef) {
      this[loadingRef] = true
      if (this.mode === 'update') {
        const allowChanges = ['name', 'tag', 'color', 'icon', 'auto_apply', 'bankaccount_a_id', 'bankaccount_b_id', 'bankaccount_c_id', 'bankaccount_kb_id', 'bankaccount_tmw_id', 'kb_visible_all', 'condition_deposit_count', 'condition_total_deposits', 'condition_min_bet', 'condition_min_deposit', 'condition_total_bet', 'condition_registered_age']
        // ALTER TABLE `member_groups` CHANGE `confition_total_deposits` `condition_total_deposits` DECIMAL(14,2) NULL DEFAULT NULL;
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.groupInfo[keyItem])
            changeMade[keyItem] = changes[keyItem]
          if (keyItem === 'auto_apply')
            changeMade[keyItem] = changes[keyItem].length > 0 ? 1 : 0

          if (changeMade[keyItem] === '')
            changeMade[keyItem] = null
        })

        try {
          const { data: saveMemberGroupResult } = await this.$http.put(`users/groups/${id}`, { changes: changeMade })
          this[loadingRef] = false
          if (saveMemberGroupResult.success) {
            this.groupInfo = { ...saveMemberGroupResult.member_group, auto_apply: saveMemberGroupResult.member_group.auto_apply ? [true] : [] }
            this.groupForm = { ...saveMemberGroupResult.member_group, auto_apply: saveMemberGroupResult.member_group.auto_apply ? [true] : [] }
            this.$bvToast.toast(`แอดมิน ${this.groupForm.phoneno} ${this.groupForm.name}`, {
              variant: 'success',
              title: 'แก้ไขกลุ่มลูกค้าสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            return
          }
          throw new Error(saveMemberGroupResult.error_message || '')
        } catch (err) {
          this.$swal({
            title: 'แก้ไขกลุ่มลูกค้าไม่ได้',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } else if (this.mode === 'add') {
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (keyItem === 'auto_apply')
            changeMade[keyItem] = changes[keyItem].length > 0 ? 1 : 0
          else
            changeMade[keyItem] = changes[keyItem]
        })
        try {
          const { data: saveMemberGroupResult } = await this.$http.post('users/groups', { changes: changeMade })
          this[loadingRef] = false
          if (saveMemberGroupResult.success) {
            this.groupInfo = { ...saveMemberGroupResult.member_group }
            this.groupForm = { ...saveMemberGroupResult.member_group }
            this.$bvToast.toast(` ${this.groupForm.phoneno} ${this.groupForm.name}`, {
              variant: 'success',
              title: 'เพิ่มกลุ่มลูกค้าสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            this.$router.replace({ name: 'manage-member-group-detail', params: { groupId: saveMemberGroupResult.member_group.id } })
            return
          }
          throw new Error(saveMemberGroupResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'เพิ่มกลุ่มลูกค้าผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      }
      this[loadingRef] = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
